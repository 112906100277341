/*============= Project area start  =============*/
.projects-area {
    .col-lg-4:nth-child(3) {
        .Our-Project {
            margin-top: -450px;
            @include md-screen {
                margin-top: -120px;
            }
            @include sm-screen {
                margin-top: 0px;
            }
        }
        .col-lg-12:last-child {
            .Our-Project {
                margin-top: -30px;
                @include xl-screen {
                    margin-top: -90px;
                }
                @include lg-screen {
                    margin-top: -150px;
                }
                @include md-screen {
                    margin-top: 0px;
                }
            }
        }
    }
    .col-lg-4:nth-child(1) .Our-Project {
        margin-bottom: 0px;
    }
}


.Our-Project {
    position: relative;
    z-index: 2;
    border-radius: var(--border-radius);
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    &:hover {
        box-shadow: 0px 20px 30px rgba(1, 6, 20, 0.1);
        transform: translateY(-3px);
    }
    a {
        position: relative;
        z-index: 2;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            border-radius: var(--border-ra  dius);
            left: 0px;
            bottom: 0px;
            transition: all 0.3s ease-in-out;
            z-index: 1;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, hsl(var(--base)/.6) 100%);
            }
            .content {
                position: absolute;
                bottom: 20px;
                text-align: center;
                width: 100%;
                z-index: 1;
                h4 {
                    color: hsl(var(--white));
                    margin-bottom: 5px;
                    font-weight: 600;
                }
                span {
                    color: hsl(var(--white));
                    font-weight: 500;
                }
                
            }
    }
}

