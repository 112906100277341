
*{
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
}
body{
    // font-family: var(--heading-font);
    // color: hsl(var(--body-color));
    // word-break: break-word;
    // background-color: hsl(var(--body-background));
    // min-height: 100vh;
    // display: flex;
    // flex-direction: column;

    font-family: var(--heading-font);
    color: hsl(var(--body-color));
    word-break: break-word;
    background-color: hsl(var(--body-background));
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
p{
    font-family: var(--body-font);
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    word-break: break-word;
    color: hsl(var(--body-color));
    line-height: 30px;
}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 15px 0;
    font-family: var(--heading-font);
    color: hsl(var(--heading-color));
    line-height: 1.1;
    word-break: break-word;
    @include sm-screen {
      margin: 0 0 10px 0;
    }
  }
  h1 {
    font-size: var(--heading-one);
    font-weight: 700;
  }
  h2 {
    font-size: var(--heading-two);
    font-weight: 600;
  }
  h3 {
    font-size: var(--heading-three);
    font-weight: 500;
  }
  h4 {
    font-size: var(--heading-four);
    font-weight: 500;
  }
  h5 {
    font-size: var(--heading-five);
    font-weight: 500;
  }
  h6 {
    font-size: var(--heading-six);
    font-weight: 500;
  }
  h1 > a,
  h2 > a,
  h3 > a,
  h4 > a,
  h5 > a,
  h6 > a {
    font-weight: 600;
    transition: .2s linear;
    line-height: 1.4;
    word-break: break-word;
    color: hsl(var(--heading-color));
  }

a{
    display: inline-block;
    transition: .2s linear;
    text-decoration: none;
    color: hsl(var(--body-color));
}
a:hover {
    color: hsl(var(--base));
  }

ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-radius: var(--border-radius);
  }
  select {
    cursor: pointer;
  }
  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  *:focus {
    outline: none;
  }
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: hsl(var(--white));
    &:focus {
      outline: none;
    }
  }

  .form-select:focus {
    outline: 0;
    box-shadow: none;
}