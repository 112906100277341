.full-display {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.header-transparent {
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
}
.text-sm {
    font-size: 12px !important;
}