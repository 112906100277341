/*============= Team News =============*/

.team-item {
    border-radius: var(--border-radius);
    position: relative;
    transition: all 0.4s ease-in-out;
    &:hover {
        box-shadow: 0px 20px 30px rgba(1, 6, 20, 0.1);
        transform: translateY(-3px);
    }
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius);
        left: 0px;
        bottom: 0px;
        transition: all 0.3s ease-in-out;
        z-index: 1;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, hsl(var(--base)/.3) 100%);
    }
    &__content-wrapper {
        position: absolute;
        bottom: 35px;
        width: 100%;
        text-align: center;
        z-index: 1;
        .team-name {
            h3 {
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 0;
                color: hsl(var(--white));
            }
            span {
                color: hsl(var(--white));
                font-weight: 600;
            }
        }
        .social-wrap {
            position: absolute;
            bottom: 70px;
            right: 5px;
        }
        .social-wrap {
            ul {
                li {
                    position: relative;
                    &:hover .social-team {
                        opacity: 1;
                        visibility: visible;
                        z-index: 1;
                    }
                    .social-team {
                        position: absolute;
                        bottom: 42px;
                        transition: all 0.6s ease-in-out;
                        z-index: 1;
                        visibility: hidden;
                        opacity: 0;
                        li {
                            margin-top: 2px;
                         }
                    }
                    a {
                        padding: 8px 14px;
                        background: hsl(var(--base));
                        margin-right: 10px;
                        color: hsl(var(--white));
                        border-radius: var(--border-radius-sm);
                    }
                }
            }
        }
    }
    &__thumb img {
        width: 100%;
        border-radius: var(--border-radius);
        img {
            width: 100%;
        }
    }

}


