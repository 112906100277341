/*============= Banner area start  =============*/
.banner-section {
    position: relative;
    z-index: 1;
    padding: 230px 0 180px;
    background: hsl(var(--base)/.02);
    @include lg-screen {
        padding: 230px 0 180px;
    }
    @include md-screen {
        padding:170px 0 140px;
    }
    @include sm-screen {
         padding: 145px 0 104px;
    }
    @include xsm-screen {
         padding: 125px 0 75px;
    }
    .popup-vide-wrap {
        position: absolute;
        right: 17%;
        top: 83%;
        z-index: -1;
        @include xxxl-screen {
            right: 9%;
        }
        @include xxl-screen {
            right: 6%;
        }
        @include lg-screen {
            right: -3%;
            top: 77%;
        }
        @include md-screen {
            right:0%;
            top: 83%;
        }
        @include msm-screen {
            right: 17%;
            top: 88%;
        }
        @include sm-screen {
            right: 6%;
            top: 93%;
        }
        .waves {
            position: absolute;
            width: 650px;
            height: 650px;
            border-radius: 40%;
            @include lg-screen {
                width: 490px;
                height: 490px;
                right: 30px;
            }
            @include md-screen {
                width: 390px;
                height: 390px;
                right: 59px;
                bottom: 24px;
            }
            @include sm-screen {
                width: 520px;
                height: 520px;
                right: -14px;
                bottom: -15px;
            }
            @include msm-screen {
                width: 350px;
                height: 350px;
                right: 24px;
                bottom: 38px;
            }
            @include xxsm-screen {
                width: 250px;
                height: 250px;
                right: 9px;
                bottom: 38px;
            }
        }
    }
    .banner-effect-2 {
        content: "";
        position: absolute;
        bottom: 33%;
        right: 15%;
        width: 651px;
        height: 273px;
        background-color: hsl(var(--base)/0.9);
        opacity: 0.8;
        filter: blur(93px);
        border-radius: 1% 50% 50% 0%;
        z-index: -1;
        @include xxl-screen {
            right: 15%;
            width: 491px;
        }
        @include lg-screen {
            bottom: 32%;
            right: 5%;
            width: 450px;
        }
        @include md-screen {
            bottom: 29%;
            right: 8%;
            width: 350px;
        }
        @include sm-screen {
            bottom: 19%;
            right: 27%;
            width: 350px;
        }
        @include msm-screen {
            bottom: 21%;
            right: 20%;
            width: 62px;
        }
    }
    .banner-right-shape {
        position: absolute;
        right: 0;
        height: 100%;
        width: 50%;
        background: hsl(var(--base));
        top: 0;
        border-radius: 472px 0 0px 418px;
    }
}

.banner-left{
    &__content {
        span {
            font-size: 20px;
            font-weight: 600;
            background: hsl(var(--base));
            padding: 5px 17px;
            color: hsl(var(--white));
            border-radius: 20px 0px 20px;
            display: inline-block;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 60px;
            text-transform: uppercase;
            margin-bottom: 20px;
            font-weight: 800;
            color: hsl(var(--black));
            line-height: 1.2;
            span {
                color: hsl(var(--base));
                background-image: linear-gradient(to right,  hsl(var(--base)) 0%,  hsl(var(--base-light)) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            @include lg-screen {
                font-size: 40px;
            }
            @include md-screen {
                font-size: 24px;
                margin-bottom: 11px;;
            }
            @include sm-screen {
                font-size: 32px;
            }
            @include xxsm-screen {
                font-size: 28px;
            }
        }
        p {
            margin-bottom: 35px;
            @include md-screen {
                margin-bottom: 15px;
            }
        }
        .btn {
            @include md-screen {
                padding: 12px 15px;
            }
            @include xxsm-screen {
                padding: 8px 10px;
            }
        }
    }
}

.banner-right-wrap {
    text-align: right;
    position: relative;
    margin-left: 30px;
    .banner-shape-1 {
        position: absolute;
        width: 71px;
        right: 8%;
        top: 20%;
    }
    .banner-shape-2 {
        position: absolute;
        left: -50px;
        width: 70px;
        top: 80px;
        @include md-screen {
            left: 121px;
            width: 70px;
            top: -99px;
        }
        @include sm-screen {
            left: 57%;
            top: -73px;
            width: 50px;
        }
    }
}