/*====================== How it works ====================*/

.service {
    box-shadow: 0px 15px 30px rgb(61 90 125 / 8%);
    border-radius: var(--border-radius);
    padding: 30px 30px 35px;
    border: 2px solid hsl(var(--base)/.07);
    transition: all .4s ease-in-out;
    text-align: center;
    position: relative;
    overflow: hidden;

    &:hover {
        box-shadow: 0px 20px 30px rgba(1, 6, 20, 0.1);
        border: 2px solid hsl(var(--base)/.4);
        transform: translateY(-3px);
        .service {
            &__icon {
                i {
                    transform: scaleX(-1);
                }
                &::before {
                    background: hsl(var(--base)/0.3);
                }
            }
        }
        &::after {
            left: -25px;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: -13px;
        left: -225px;
        width: 149px;
        height: 116px;
        background: hsl(var(--base)/0.04);
        transform: rotate(168deg);
        border-radius: 10px;
        transition: all .4s ease-in-out;
    }
    &__icon {
        margin-bottom: 20px;
        position: relative;
        transition: all .6s ease-in-out;
        i {
            font-size: 27px;
            color: hsl(var(--base));
            margin-top: 2px;
            height: 70px;
            width: 70px;
            text-align: center;
            background: hsl(var(--base)/0.2);
            line-height: 70px;
            border-radius: 50%;
            transition: transform 0.5s ease;
        }
    }
    &__content  {
        h3 {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        a {
            font-size: 16px;
            font-weight: 600;
            margin-top: 11px;
        }
    }
}