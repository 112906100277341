/*====================== Why choose ====================*/
.why-choose-area.py-80 {
    position: relative;
    .why-choose-bg {
        height: 457px;
        width: 833px;
        position: absolute;
        right: 0;
        top: 0;
        opacity: .2;
    }
}


.why-choose-us {
    &__thumb {
        position: relative;
        .popup-vide-wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-95%, -50%);
            .play-video {
                height: 80px;
                width: 80px;
                line-height: 84px;
                display: inline-block;
                border-radius: 50%;
                position: relative;
                z-index: 3;
                text-align: center;
            }
            .waves {
                position: absolute;
                width: 150px;
                height: 150px;
                background: hsl(var(--base)/0.2);
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                border-radius: 50%;
                right: -34px;
                bottom: -35px;
                z-index: 1;
                -webkit-animation: waves 3s ease-in-out infinite;
                animation: waves 3s ease-in-out infinite;
            }
        }
    }
    &__content {
       h3 {
        font-size: 28px;
        font-weight: 600; 
        @include msm-screen {
            font-size: 22px;
        }
       }
    }
    &__topic {
        display: flex;
        flex-direction: column;
        align-items: center;
       .item {
        margin-bottom: 15px;
        .why-title-cont {
            // @include md-screen {
            //     bottom: -100px;
            //     left: 50%;
            //     transform: translateX(-50%);
            // }
            i {
                color: hsl(var(--base));
                font-size: 24px;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                line-height: 50px;
                text-align: center;
                background: hsl(var(--base)/.2);
            }
            .content {
                width: calc(100% - 50px);
                h4 {
                    color: hsl(var(--black));
                    font-weight: 700;
                    font-size: 20px;
                    margin-bottom: 3px;
                }
                p{
                    width: 60%;
                    @include md-screen {
                        width: 80%;
                    }
                    @include xsm-screen {
                        width: 100%;
                    }
                }
            }

        }
       }
    }


    .wave-1 {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }
      
      .wave-2 {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }
      
      .wave-3 {
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
      }
}


