/*====================== Offer ====================*/
.pricing-plan-item {
    box-shadow: 0px 15px 30px rgb(61 90 125 / 8%);
    border-radius: var(--border-radius);
    padding: 40px 30px 42px;
    border: 1px solid hsl(var(--base)/.07);
    transition: all .4s ease-in-out;
    position: relative;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, hsl(var(--base)/.2) 100%);
    .price-img-1 {
        position: absolute;
        top: 0;
        left: 0;
    }
    .price-img-2 {
        position: absolute;
        top: 0;
        right: 0;
        transform: rotate(90deg);
    }
    &:hover {
        box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 10%);
        border: 1px solid hsl(var(--base)/.4);
        transform: translateY(-3px);
    }
    .pricing-plan-item {
        &__top {
            text-align: center;
            .title {
                font-size: 40px;
                font-weight:  700;
                margin-bottom: 0px;
            }
        }
        &__price {
            .title {
                font-weight: 600;
                font-size: 35px;
                margin-bottom: 20px;
                span {
                    font-size: 16px;
                    color: hsl(var(--black)/.5);
                }
            }
        }
        &__list {
            margin-bottom: 25px;
            ul {
                li {
                    // border-bottom: 1px solid hsl(var(--black) /.1);
                    padding-bottom: 8px;
                    margin-bottom: 10px;
                    font-size: 15px;
                    display: flex;
                    i {
                        color: hsl(var(--base));
                        margin-right: 10px;
                        margin-top: 3px;
                    }
                }
            }
        }
        &__bottom {
            a {
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
}
