/*============= Video Start Here =======================*/
.video-left {
    &:hover {
        .video-left__thumb img {
            transform: scale(1.2);
        }
    }

    &__thumb {
        position: relative;
        z-index: 3;
        margin-bottom: 30px;
        overflow: hidden;
        border-radius: 16px;
        img {
            border-radius: 16px;
            width: 100%;
            transition: all .5s linear;
        }
        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: hsl(var(--dark));
            opacity: .4;
            border-radius: 16px;
            z-index: 1;
        }
    }
    &__content {
        a {
            &:hover {
                color: inherit;
            }
        }
    }
    .popup-vide-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-45%, -50%);
        z-index: 4;
    } 
    .meta {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .right-side-meta.ms-auto .like {
            margin-left: 10px;
            i {
                color: hsl(var(--base));
            }
        }
        
    }
    .date i {
        color: hsl(var(--base));
    }   
}






