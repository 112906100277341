  /*=================== Account =========*/

  .account {
    .login-bg-img {
        position: absolute;
        width: 48%;
        height: 100%;
        border-radius: 0;
        top: 0;
        object-fit: cover;
        @include md-screen {
            display: none;
        }
    }
  }

.account-inner{
    position: relative;
}

.account-form  {
    border: 1px solid hsl(var(--base)/0.1);
    background-color: hsl(var(--white)/.1);
    padding: 50px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @include lg-screen {
        padding: 40px 30px;
    }
    @include md-screen {
        padding: 35px 25px;
    }
    @include sm-screen {
        padding: 30px 20px;
    }
    @include xsm-screen {
        padding: 30px 15px;
    }
}


.account-thumb {
    img {
        @include fit_image; 
    }
    @media (min-width: 1199px) {     
        position: absolute;
        left: 20px;
        top: 0;
        bottom: 0;
        width: 47%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
