
/*====================== Breadcumb Css Start ====================*/
.breadcumb {
    position: relative;
    z-index: 1;
    padding: 160px 0 70px;
    z-index: 1;
    background: hsl(var(--base)/0.02);
    background-image: linear-gradient(to bottom, hsl(var(--base)/0.4) 0%, hsl(var(--base-light)/0.01) 100%);
    @include md-screen {
        padding: 100px 0 50px;
    }
    .banner-effect-1 {
        content: "";
        position: absolute;
        top: 67%;
        left: 50%;
        width: 151px;
        height: 173px;
        background-color: hsl(var(--base)/0.9);
        opacity: 0.8;
        filter: blur(93px);
        border-radius: 1% 50% 50% 0%;
        z-index: -1;
        transform: translate(-50%, -50%);
    }
    &__wrapper {
        text-align: center;
    }
    &__title {
        color: hsl(var(--dark)); 
        font-weight: 700;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__item {
        color: hsl(var(--dark)); 
        padding: 0 5px;
        font-weight: 400;
        font-size: 16px;
            @include msm-screen {
                font-size: 15px;
            }
        &-text {
            color: hsl(var(--dark)); ;
        }
    }
    &__link {
        color: hsl(var(--dark));  
        font-weight: 500;
        &:hover {
            color: hsl(var(--white)); ;
        }
    }
}
// ====================== Breadcumb Css End ====================
