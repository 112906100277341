.py-120 {
  padding-top: 60px;
  padding-bottom: 60px;
  @media (min-width: 576px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media (min-width: 992px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.pt-120 {
    padding-top: 60px;
    @media (min-width: 576px) {
    padding-top: 80px;
  }
    @media (min-width: 992px) {
    padding-top: 120px;
  }
}
.py-80 {
  padding-top: 40px;
  padding-bottom: 40px;
  @media (min-width: 576px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media (min-width: 992px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.pt-80 {
  padding-top: 40px;
  @media (min-width: 576px) {
  padding-top: 60px;
  }
    @media (min-width: 992px) {
    padding-top: 80px;
  }
}
.pb-80 {
  padding-bottom: 40px;
  @media (min-width: 576px) {
  padding-bottom: 60px;
  }
    @media (min-width: 992px) {
    padding-bottom: 80px;
  }
}
.pb-100 {
  padding-bottom: 50px;
  @media (min-width: 576px) {
  padding-bottom: 80px;
  }
    @media (min-width: 992px) {
    padding-bottom: 100px;
  }
}
.py-60 {
    padding-top: 30px;
    padding-bottom: 30px;
    @media (min-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
    @media (min-width: 992px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.pt-60 {
    padding-top: 30px;
    @media (min-width: 576px) {
    padding-top: 40px;
  }
    @media (min-width: 992px) {
    padding-top: 60px;
  }
}
.pt-40 {
  padding-top: 30px;
    @media (min-width: 576px) {
      padding-top: 35px;
  }
    @media (min-width: 992px) {
      padding-top: 40px;
  }
}
  .pb-60 {
    padding-bottom: 30px;
    @media (min-width: 576px) {
    padding-bottom: 40px;
  }
    @media (min-width: 992px) {
    padding-bottom: 60px;
  }
}
  .pb-30 {
    padding-bottom: 20px;
    @media (min-width: 576px) {
    padding-bottom: 25px;
  }
    @media (min-width: 992px) {
    padding-bottom: 30px;
  }
}

// ========================= Padding Md 60 Bottom =========================== 
.pt-md-60 {
     padding-top: 60px;
     @media (min-width: 576px) {
       padding-top: 80px;
     }
     @media (min-width: 992px) {
       padding-top: 60px;
     }
}
  .pb-md-60 {
    padding-bottom: 60px;
    @media (min-width: 576px) {
      padding-bottom: 80px;
    }
    @media (min-width: 992px) {
      padding-bottom: 60px;
    }
}

