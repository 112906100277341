  /*=================== Testimonials =========*/
.testimonials {
    position: relative;
    overflow: hidden;
}

// .testimonails-card {
//     padding: 0 10px;
//     height: 100%; 
// }

.testimonial-item {
    background-color: hsl(var(--base)/.03);
    // border-top: 10px solid hsl(var(--base));
    padding: 30px 20px;
    border-radius: var(--border-radius);
    position: relative;
    height: 100%; 
    margin-bottom: 50px;
    display: flex;
    @include xsm-screen {
        padding: 25px 15px;
    }
    @include msm-screen {
        margin-bottom: 35px;
        flex-direction: column;
        overflow: hidden;
    }
    &__quate {
        position: absolute;
        right: 62px;
        width: 64px;
        opacity: 0.08;
        top: 6px;
        i {
            font-size: 93px;
            color: hsl(var(--base)/.7);
        }
    }
    &__content {
        justify-content: space-between;
    }
    &__info {
        margin-bottom: 15px;
        @include xsm-screen {
            margin-bottom: 10px;
        }
    }
    &__thumb {
        z-index: 22;
        padding: 12px;
        width: 538px;
        @include msm-screen {
            width: 250px;
        }
        // &:before {
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     height: 50%;
        //     width: 100%;
        //     background-color: hsl(var(--base));
        //     border-radius: 120px 120px 0 0;
        //     content: "";
        // }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 2;
            position: relative;
        //     @include msm-screen {
        //         width: inherit;
        //         height: inherit;
        //         object-fit: inherit;            }
        }
    }
    &__details {
        width: calc(100% - 60px);
        padding-left: 20px;
        @include xsm-screen {
            width: calc(100% - 45px);
            padding-left: 10px;
        }
    }
    &__name {
        margin-bottom: 0;
        color: hsl(var(--black) );
    }
    &__designation {
        color: hsl(var(--black) / .8);
    }
    &__desc {
        color: hsl(var(--black) / .5);
        margin-bottom: 20px;
        @include xsm-screen {
            margin-bottom: 10px;
        }
    }
}

.rating-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__item {
        padding: 0 1px;
        color: hsl(var(--base));
    }
}