
/*====================== Blog Style ====================*/
.blog-item {
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
    height: 100%;
    &:hover {
        .blog-item {
            &__thumb {
                img {
                    transform: scale(1.2);
                }
            }
            &__title {
                img {
                    color: hsl(var(--base));
                }
            }
        }
    }
    &__thumb {
        overflow: hidden;
        border-radius: 0px;
        max-height: 300px;
        img {
            transition: .3s linear;
            border-radius: 0;
            width: 100%;
            height: 100%;
            @include fit_image; 
        }
    }
    &__content {
        background-color: hsl(var(--white)/.07); 
        padding: 35px 25px;
        height: 100%;
        @include sm-screen {
            padding: 30px 20px;
        }
        @include xsm-screen {
            padding: 25px 15px;
        }
    }
    &__title {
        margin-top: 20px;
        @include msm-screen {
            margin-top: 15px;
        }
        &-link {
            overflow:hidden;
            text-overflow:ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    &__desc {
        margin-bottom: 25px;
        overflow:hidden;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @include sm-screen {
            margin-bottom: 15px;
        }
    }
}


.blog-bottom-wrap {
    border-top: 1px solid hsl(var(--base)/.2);
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .like-comment {
        display: flex;
        align-items: center;
        .left-auth {
            img {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                object-fit: cover;
                margin-right: 10px;
            }
        }
    }
    .auth-name {
        h5 {
            font-size: 16px;
            margin-bottom: 0;
            font-weight: 600;
            span {
                color: hsl(var(--dark)/.6);
            }
        }
    }
    .comments-like {
        span {
            font-size: 15px;
            margin-left: 10px;
            display: inline-block;
            i {
                color: hsl(var(--base));
                font-size: 14px;
            }
        }
    }
}




