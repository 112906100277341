
/*================ Top Header Start Here ================*/
.header-main-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.3s linear;
    padding-top: 0;
}

.header-top{
    border-bottom: 1px solid hsl(var(--base) / .08);
    padding:10px 0px;
    position: relative;
    z-index: 2;
    background-color: hsl(var(--base)/.2);
    @include md-screen {
        display: none;
    }
    
    .social-list.style-two .social-list__link {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }
}
.top-header-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @include md-screen {
        justify-content: center;
    }
    
    @include xsm-screen {
        justify-content: center;
    }
}
.latest-top.latest-slider {
    @include md-screen {
        text-align: center;
    }
    p {
        color: hsl(var(--dark));
        line-height: 1.4;
    }
}

.contact-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -20px;
    @include md-screen {
        justify-content: center;
    }
    @include sm-screen {
        display: none;
    }
    &__item {
        padding: 2px 20px 0px;
        position: relative;
        color: hsl(var(--white));
        &:last-child::before {
            display: none;
        }
        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 15px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: hsl(var(--dark) /.4);
        }
        &-icon {
            font-size: 16px;
            text-align: center;
            color: hsl(var(--base));
            display: inline-block;
            margin-right: 10px;
        }
    }
    &__link {
        color: hsl(var(--black));
        font-weight: 400;
        font-family: var(--body-font);
        &:hover &-icon {
            color: hsl(var(--base));
        }
    }
}
.top-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @include md-screen {
        justify-content: center;
    }
}
// Login Registration
.login-registration-list {
    margin: 0 -10px;
    @include md-screen {
        padding-right: 0;
    }
    &__item {
        color: hsl(var(--white));
        position: relative;
        &:last-child::before {
            display: none;
        }
        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 12px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: hsl(var(--dark)/.5);
        }
    }
    &__icon {
        color: hsl(var(--base));
        margin-right: 10px;
        font-size: 16px;
        i {
            color: hsl(var(--base));
        }
    }
   &__link {
        color: hsl(var(--white));
        font-weight: 400;
        font-family: var(--body-font);
        @include msm-screen {
            font-size: 15px;
        }
        &:hover {
            color: hsl(var(--base));
            .login-registration-list__icon {
                color: hsl(var(--white));
            }
            @include md-screen {
               color: hsl(var(--base));
            }
        }
    }
}

// Language Box
.language-box {
    display: flex;
    align-items: center;
    position: relative;
    .select {
        color: hsl(var(--dark));
        background-color: transparent;
        border: 1px solid transparent;
        padding: 3px 0px;
        font-size: 15px;
        font-weight: 400;
        border-radius: 0px;
        option {
            background-color: hsl(var(--dark));
        }
    }
}


li.login-registration-list__item .social-list__item .social-list__link {
    height: 30px;
    width: 30px;
    color: hsl(var(--base)) !important;
    &:hover {
        color: hsl(var(--white)) !important;
    }
}

// ================ Top Header End Here ================
