/*============** Header area **============*/

/*== Sticky header ==*/
.header {
    position: relative;
    z-index: 5;
    @include md-screen {
        top: 0px;
        padding: 10px 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 7;
        max-height: 101vh;
        overflow-y: auto;
        background-color: hsl(var(--white));
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
        }
    }
    &.fixed-header {
        background-color: hsl(var(--white));
        position: fixed;
        transition: 0.4s linear;
        top: 0px;
        animation: slide-down 0.8s;
        width: 100%;
        box-shadow: rgb(9 9 9/ 5%) 0px 1px 0px;
        border-bottom: 1px solid hsl(var(--white)/.08);
    }
}

.header-main-area {
    .container-fluid {
        padding: 0 100px;
        @include xl-screen {
            padding: 0 60px;
        }
        @include msm-screen {
            padding: 0 25px;
        }
    }
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    @include md-screen {
        padding: 0px 0;
    }
    .logo-wrapper {
        img {
            width: 160px;
            @include md-screen {
                width: 160px;
            }
        }
    }

    .menu-wrapper {
        @include md-screen {
            display: none;
        }
        ul.main-menu {
            display: flex;
            li {
                    transition: all .4s;
                }
                a {
                    padding: 0px 15px;
                    font-weight: 700;
                    color: hsl(var(--dark));
                    @include lg-screen {
                        padding: 0px 12px;
                    }
                    &:hover {
                    color: hsl(var(--base));
                }
            }
        }
    }

    .menu-right-wrapper {
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            li {
                margin-left: 20px;
                &:first-child {
                    margin-left: 0px;
                }
                &:nth-child(3) {
                    @include md-screen {
                        display: none;
                        visibility: hidden;
                    }

                }
                &:last-child {
                        display: none;
                        visibility: hidden;
                    @include md-screen {
                        display: block;
                        visibility: visible;
                    }

                }
                .sidebar-menu-show-hide {
                    background: hsl(var(--base));
                    padding: 9px 12px;
                    color: hsl(var(--white));
                    border-radius: 12px 0 12px;
                }
            }
        }
    }

}

.menu-search-wrap {
    position: relative;
    width: 10px;
    .menu-search-wrap--search-icon {
        cursor: pointer;
        i {
            font-size: 17px;
        }
    }
    .header-search-hide-show {
        display: none;
        transition: all .4s ease-in-out;
        &.show {
            display: block;
            right: 22px;
            transition: all .4s ease-in-out;
        }
    }
    .close-hide-show {
        display: none;
        &.show{
            display: block;
            position: relative;
            z-index: 2;
        }
    }
}

.search-box.header-search {
    width: 260px;
    position: absolute;
    right: -7px;
    top: -12px;
    z-index: 2;
    transition: all .4s ease-in-out;
    @include xsm-screen {
        width: 170px;
    }
    input.form--control {
        padding-right: 52px;
        background: hsl(var(--white));
        border: 1px solid hsl(var(--base)/.2);
        &::placeholder {
            color: hsl(var(--dark));
        }
    }

    button.search-box__button {
        top: 24px;
        right: 0;
        background: hsl(var(--base));
        height: 100%;
        padding: 13px;
        border-radius: 0 10px 10px 0px;
        border: 1px solid hsl(var(--white));
        i {
            color: hsl(var(--white));
        }
    }
}








